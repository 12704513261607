import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import InternetConection from "./components/layout/InternetConection";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <InternetConection />
    <App />
  </Provider>
);

/*
  Hot Module Replacement(Hot Reloading)
  There is one thing that you can do in the src/index.js file to improve your development experience as
  a developer. But it is optional and shouldn’t overwhelm you in the beginning when learning React.
  In create-react-app it is already an advantage that the browser automatically refreshes the page
  when you change your source code. Try it by changing the helloWorld variable in your src/App.js
  file. The browser should refresh the page. But there is a better way of doing it.
  Hot Module Replacement (HMR) is a tool to reload your application in the browser. The browser
  doesn’t perform a page refresh. You can easily activate it in create-react-app. In your src/index.js,
  your entry point to React, you have to add one little configuration
*/
// if (module.hot) {
//   module.hot.accept();
// }