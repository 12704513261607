import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache, from, split } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import Spinner from "react-bootstrap/Spinner";
import MainLayout from "./pages/main/MainLayout";
import { orgLoader } from "./util/auth";
import Error from "./components/layout/Error";
import HomePage from "./pages/auth/HomePage";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store";
import { ApolloLink } from "@apollo/client/core";


const lazyRetry = function (componentImport) {
  // debugger
  return new Promise((resolve, reject) => {
    // try to import the component
    // debugger
    componentImport()
      .then((component) => {
        // debugger
        resolve(component);
      })
      .catch((error) => {
        // TO DO
        // debugger;
        console.log(error);
        window.location.reload();
        reject(error); // there was an error
      });
  });
};

const StudentInfoPage = lazy(() =>
  lazyRetry(() => import("./pages/main/students/StudentInfoPage"))
);
const StudentsPage = lazy(() =>
  lazyRetry(() => import("./pages/main/students/StudentsPage"))
);
const ProfilePage = lazy(() =>
  lazyRetry(() => import("./pages/main/profile/ProfilePage"))
);
const WorklistPage = lazy(() =>
  lazyRetry(() => import("./pages/main/worklist/WorklistPage"))
);
const CheckOutPage = lazy(() =>
  lazyRetry(() => import("./pages/main/worklist/CheckOutPage"))
);
const AppointmentDetailsPage = lazy(() =>
  lazyRetry(() => import("./pages/main/worklist/AppointmentDetailsPage"))
);
const WalkInPage = lazy(() =>
  lazyRetry(() => import("./pages/main/worklist/WalkInPage"))
);
const InboxPage = lazy(() =>
  lazyRetry(() => import("./pages/main/inbox/InboxPage"))
);
const ReceivedRecordPage = lazy(() =>
  lazyRetry(() => import("./pages/main/inbox/ReceivedRecordPage"))
);
const SentMessagePage = lazy(() =>
  lazyRetry(() => import("./pages/main/inbox/SentMessagePage"))
);
const VaccineRequestPage = lazy(() =>
  lazyRetry(() => import("./pages/main/inbox/VaccineRequestPage"))
);

const Loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <Spinner as="span" animation="border" variant="primary" />
  </div>
);

const router = createBrowserRouter([
  {
    path: "",
    element: <HomePage />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        loader: orgLoader,
        element: <MainLayout />,
        children: [
          {
            path: "profile",
            element: (
              <Suspense fallback={Loading}>
                <ProfilePage />
              </Suspense>
            ),
          },
          {
            path: "worklist",
            element: (
              <Suspense fallback={Loading}>
                <WorklistPage />
              </Suspense>
            ),
          },
          {
            path: "worklist/checkout/:appointmentId",
            element: (
              <Suspense fallback={Loading}>
                <CheckOutPage />
              </Suspense>
            ),
          },
          {
            path: "worklist/appointment/:appointmentId",
            element: (
              <Suspense fallback={Loading}>
                <AppointmentDetailsPage />
              </Suspense>
            ),
          },
          {
            path: "worklist/walkin/:studentId",
            element: (
              <Suspense fallback={Loading}>
                <WalkInPage />
              </Suspense>
            ),
          },
          {
            path: "worklist/walkin/:studentId/:medicalRecord/:appoinmentId",
            element: (
              <Suspense fallback={Loading}>
                <WalkInPage />
              </Suspense>
            ),
          },
          {
            path: "inbox",
            element: (
              <Suspense fallback={Loading}>
                <InboxPage />
              </Suspense>
            ),
          },
          {
            path: "inbox/medical-record/:recordId",
            element: (
              <Suspense fallback={Loading}>
                <ReceivedRecordPage />
              </Suspense>
            ),
          },
          {
            path: "inbox/sent-message/:messageId",
            element: (
              <Suspense fallback={Loading}>
                <SentMessagePage />
              </Suspense>
            ),
          },
          {
            path: "inbox/vaccine-request/:requestId",
            element: (
              <Suspense fallback={Loading}>
                <VaccineRequestPage />
              </Suspense>
            ),
          },
          {
            path: "students",
            element: (
              <Suspense fallback={Loading}>
                <StudentsPage />
              </Suspense>
            ),
          },
          {
            path: "students/:studentId",
            element: (
              <Suspense fallback={Loading}>
                <StudentInfoPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

const getCountryLinks = country => {
  // debugger
  let links = {
    database:
      'https://production-eg--ayamedica-c7wpka.apollographos.net/graphql',
    monolith: '//monolith-eg-production-b341b1f6eac2.herokuapp.com/graphql'
  }

  if (country === 'EG') {
    return links
  } else if (country === 'SA') {
    links = { 
      database:
        'https://production-sa--ayamedica-c7wpka.apollographos.net/graphql',
      monolith: '//monolith-ksa-production-e03b7e03d1ff.herokuapp.com/graphql'
    }
    return links
  }
}

const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const wsLink = (language, link) =>
  new GraphQLWsLink(
    createClient({
      url: protocol + link?.monolith,
      options: {
        reconnect: true
      },
      connectionParams: {
        Authorization: sessionStorage.getItem('token'),
        language: language
      }
    })
  )

const httpLink = link =>
  createUploadLink({
    uri: link?.database
    // uri: 'https://current--ayamedica-supergraph-r8r9a5.apollographos.net/graphql',
    // uri: 'http://192.168.1.20:4003/graphql',
  })

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = (language, country) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink(language, getCountryLinks(country)),
    httpLink(getCountryLinks(country))
  )

const authLink = (language, token) =>
  setContext((_, { headers }) => {
    // const token = sessionStorage.getItem('token')
    return {
      headers: {
        ...headers,
        authorization: (token && token !== "null") ? token : '',
        language: language,
        'keep-alive': 'true',
        'content-type': 'application/json'
      }
    }
  })

  const successLink = new ApolloLink((operation, forward) => {
    // Use the `forward` function to pass the operation down the middleware chain.
    return forward(operation).map((response) => {
      // This function is called when a response is received.
      const operationName = Object.keys(response.data)
      operationName.forEach((name) => {
        const code = response.data[name]?.code
        if (code === 450) {
          sessionStorage.clear()
          const redirectURL = 'https://sso.ayamedica.com/'
          // const redirectURL = "http://localhost:3002"
          window.location.href = redirectURL
        }
      })
    
      // Return the response to continue the chain.
      return response;
    });
  });


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    // debugger
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // debugger
      if (message === 'Please login to continue' || extensions?.helperMessage === "Please login to continue") {
        // sessionStorage.clear()
        const redirectURL = 'https://sso.ayamedica.com/'
        window.location.href = redirectURL
        // window.location.reload();
      }
    })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const createApolloClient = (language, token, country) => {
  // debugger
  return new ApolloClient({
    link: from([
      errorLink,
      successLink,
      authLink(language, token).concat(splitLink(language, country))
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: o => (o.id ? `${o.__typename}-${o.id}` : null)
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        returnPartialData: true
      },
      query: {
        errorPolicy: 'all',
        returnPartialData: true
      }
    }
  })
}

function App() {
  const language = useSelector((state) => state.auth.language);
  const country = useSelector((state) => state.auth.country);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  if (sessionStorage.getItem("country") === null || sessionStorage.getItem("country") === "undefined") {
    // debugger
    dispatch(
      authActions.saveCountry(
        document.location.search?.split("country=")[1]?.split("?")[0]
      )
    );
  }
  const client = createApolloClient(
    language,
    token,
    country
      ? country
      : document.location.search?.split("country=")[1]?.split("?")[0]
  );



  return <ApolloProvider client={client}>
    <RouterProvider router={router} />
  </ApolloProvider>
}

export default App;
