import { useRouteError } from "react-router-dom";
// import { useSelector } from "react-redux";
import ayamedicaIcon from '../../assets/images/error.png';
// import words from "../../assets/words";
import classes from './Error.module.scss'

const Error = () => {
    const error = useRouteError();
    console.log(error)
    // const language = useSelector(state => state.auth.language);

    // if (error.status === 404) {
    //     title = 'Not Found!';
    //     message = 'Page Not Found';
    // }

    // if (error.status === 500) {
    //     message = JSON.parse(error.data).message;
    // }

    return (
        <div className={classes.content}>
            <div className={classes.image}>
                <img src={ayamedicaIcon} alt="Ayamedica Error" />
            </div>

            {/* <h1>{words.ERROR[language]}</h1> */}
            {/* <p>{words.ERROR_MESSAGE[language]}</p> */}
        </div>
    )
}

export default Error;