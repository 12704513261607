import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, layoutActions } from '../../store';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import { ImInfo } from 'react-icons/im';
import Alert from 'react-bootstrap/Alert';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import ProfileSettings from '../../components/layout/ProfileSettings';
import SelectLanguage from '../../components/layout/SelectLanguage';
import MainNavigation from '../../components/layout/MainNavigation';
import words from '../../assets/words';
import '../../util/firebase';
import { getFirebaseToken, onMessageListener } from '../../util/firebase';
import Button from '../../components/ui/Button';
import { SAVE_FIREBASE_TOKEN } from '../../graphql/auth/Mutations';
import { useMutation, useQuery } from '@apollo/client';
import { READ_NOTIFICATION_NUMBERS } from '../../graphql/main/Queries';
import { CANCEL_APPOINTMENT, OPEN_RECORD_REQUEST } from '../../graphql/main/Mutations';
import classes from './MainLayout.module.scss';
import { IoClose } from 'react-icons/io5';
import IconButton from '../../components/ui/IconButton';
import withModal from '../../components/ui/withModal';
import ConfirmationMessage from '../../components/ui/ConfirmationMessage';

const MainLayout = () => {
    const { language, user, firebaseToken, organization } = useSelector(state => state.auth);
    const { vaccineType, trialToast } = useSelector(state => state.layout);
    const { pathname, state } = useLocation();
    const appoinmentId = JSON.parse(sessionStorage.getItem("appoinmentId"))
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '', recordId: '', messageId: '' });
    const [newFirebaseToken, setNewFirebaseToken] = useState(firebaseToken);
    const [showWalkInModal, setWalkInModal] = useState(false)
    const [savePage, setSavePage] = useState({
        id: "worklist",
    })
    getFirebaseToken(setNewFirebaseToken);

    const [saveFirebaseToken] = useMutation(SAVE_FIREBASE_TOKEN, {
        onCompleted: () => {
            // debugger
            dispatch(authActions.saveFirebaseToken(newFirebaseToken))
            getNotificationNumber({
                variables: {
                    branchId: organization?.branchId
                }
            });
        }
    });

    const { refetch: getNotificationNumber } = useQuery(READ_NOTIFICATION_NUMBERS, {
        variables: {
            branchId: organization?.branchId
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            // debugger
            dispatch(layoutActions.saveNotificationNumber({ notificationNumber: data.notifactionBadgeNumber.data?.unReadMedicalRecordLength }))
        },
        // onError: (error) => {
        //     debugger
        // }
    })

    const [openRecordRequest] = useMutation(OPEN_RECORD_REQUEST, {
        onCompleted: () => {
            // debugger
            getNotificationNumber().then(() => {
                // debugger
                navigate(`inbox/medical-record/${notification.recordId}`);
            })
        },
        // onError: (error) => {
        //     debugger
        // }
    });

    const [cancelAppointment, { loading: cancelAppLoading }] = useMutation(CANCEL_APPOINTMENT, {
        // ignoreResults: true,
        onCompleted: (data) => {
            if(data.cancelSchoolAppointment.success){
            navigate(-1);
            setWalkInModal(false)
            }
            else
            {
                navigate(-1)
                setWalkInModal(false)
            }
        },
    })

    useEffect(() => {
        // debugger
        if ((newFirebaseToken !== firebaseToken) && user) {
            saveFirebaseToken({
                variables: {
                    firebaseToken: newFirebaseToken,
                    dashboardFirebaseToken: null
                }
            })
        }
    }, [newFirebaseToken, firebaseToken, saveFirebaseToken]);


    // if (isTokenFound) {
    //     console.log("permission enabled 👍🏻")
    // }
    // else {
    //     console.log("Need notification permission ❗️")
    // }

    onMessageListener().then(payload => {
        console.log(payload);
        setShow(true); 
        setNotification({ title: payload.notification.title, type: payload.data.type, body: payload.notification.body, recordId: payload.data.medicalRecordId, messageId: payload.data.messageId })
        getNotificationNumber()
    }).catch(err => console.log('failed: ', err));

    const goBack = () => {
        if (pathname.includes("walkin")) {
            setWalkInModal(true)
        } else {
            navigate(-1);
        }
    }

    const goToInbox = () => {
        // debugger
        setShow(false);
        openRecordRequest({
            variables: {
                messageId: notification.messageId
            }
        })
    }
console.log(state);
    const cancelWalkIn = () => {
        cancelAppointment({
            variables: {
                appointmentId: state?.appoinmentId ? state?.appoinmentId : appoinmentId,
            }
        })
    }
    const goToPage = () => {
        navigate(`/${savePage.id}`)
        setWalkInModal(false)
    }

    const ConfirmationWlkIn = useMemo(() => withModal(ConfirmationMessage), [])

    return (
        <section className={classes.section}>
            {
                showWalkInModal &&
                <ConfirmationWlkIn confirmLoading={cancelAppLoading} show={showWalkInModal} title={words.WALK_IN[language]} onHide={() => setWalkInModal(false)}
                    message={words.WALKIN_MODAL_CONTENT[language]}
                    confirm={cancelWalkIn}
                    cancel={goToPage}
                />
            }
            <ToastContainer className="p-3">
                <Toast onClose={() => setShow(false)} show={show} autohide={false} animation position='top-end'>
                    <Toast.Header closeButton={true}>
                        <strong>{notification.title}</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <div>
                            <p>{notification.body}</p>
                            {
                                notification?.type === "MEDICAL_RECORD" &&
                                <Button onClick={goToInbox}>{words.GO_TO_INBOX[language]}</Button>
                            }
                        </div>
                    </Toast.Body>
                </Toast>
            </ToastContainer>



            {user && <>
                <MainNavigation option="worklist" setWalkInModal={setWalkInModal} setSavePage={setSavePage} />

                <main className={classes.content}>

                    <div className={classes.header}>
                        <div className={classes.title}>
                            {
                                (pathname !== "/worklist" && pathname !== "/inbox" && pathname !== "/students") &&
                                <>
                                    {language === "ar" ? <BiRightArrowAlt size={35} onClick={goBack} style={{ cursor: "pointer" }} /> : <BiLeftArrowAlt size={35} onClick={goBack} style={{ cursor: "pointer" }} />}
                                </>
                            }
                            <h3>
                                {pathname.startsWith("/organization") && words.ORGANIZATION_SETTING[language]}
                                {pathname === "/students" && words.STUDENTS[language]}
                                {pathname === "/worklist" && words.WORKLIST[language]}
                                {state && state.appointmentType === "CHECKUP" && words.CHECK_UP[language]} {state && state?.disease?.key === "hygiene" && ` / ${state?.disease?.value}`}
                                {state && state.appointmentType === "FOLLOW_UP" && words.FOLLOW_UP[language]}
                                {state && state.appointmentType === "vaccinations" && words.VACCINATION[language]}
                                {state && state.appointmentType === "WALK_IN" && words.WALK_IN[language]}
                                {pathname.startsWith("/worklist/walkin") && words.WALK_IN[language]}
                                {pathname.startsWith("/profile") && words.MY_PROFILE[language]}
                                {pathname.startsWith("/inbox") && words.INBOX[language]}
                            </h3>
                        </div>
                        <div className={classes.options}>
                            <SelectLanguage language={language} />
                            <ProfileSettings />
                        </div>
                    </div>

                    <div className={classes.outlet}>
                        {
                            organization?.branchId &&
                            <Outlet />
                        }
                    </div>

                    {/* <Alert onClose={() => { }} color="neutral">
                            <AlertTitle>{words.DAYS_LEFT_OF_TRAIL[language]}</AlertTitle>
                            <p style={{ color: "var(--primaryColor700)" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                            <p>{words.CANCEL_TRAIL[language]}</p>
                        </Alert> */}

                    {organization?.isFreeTrial && trialToast &&
                        <div className={classes.alertContainer}>
                            <Alert id='trialAlert'>
                                <Alert.Heading bsPrefix={classes.trialAlertHead}>
                                    {/* {words.DAYS_LEFT_OF_TRAIL[language]} */}

                                    <span>
                                        <ImInfo />
                                        {words.FREE_TRAIL[language]}
                                    </span>
                                    <IconButton style={{ padding: 0, color: "var(--errorColor700)" }} onClick={() => dispatch(layoutActions.closeTrialToast(false))}><IoClose /></IconButton>
                                </Alert.Heading>
                                {/* <div className={classes.trialAlertBody}>
                                    <p style={{ color: "var(--primaryColor700)" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                    <Alert.Link href="#" style={{ color: "var(--errorColor700)" }}>{words.CANCEL_TRAIL[language]}</Alert.Link>
                                </div> */}
                            </Alert>
                        </div>
                    }

                </main>
            </>}
        </section>
    )
}

export default MainLayout;