import Spinner from "react-bootstrap/Spinner";
import classes from "./OutlinedButton.module.scss";

const OutlinedButton = ({ type = 'button', children, onClick, disabled = false, style, loading = false }) => {
    return (
        <button className={classes.button} type={type} onClick={onClick} disabled={disabled || loading} style={style}>
            {loading ? <Spinner as="span" animation="border" /> : children}
        </button>
    )
}

export default OutlinedButton;