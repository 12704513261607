import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { BsBell } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import logo from "../../assets/images/new-logo/Ayamedica-final-logo-blue.svg";
import words from '../../assets/words';
import OrganizationIcon from './OrganizationIcon';
import classes from './MainNavigation.module.scss';
import { layoutActions } from '../../store';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_ID } from '../../graphql/auth/Queries';
// import { removePageNumber } from '../../store/slices/layoutSlice';

const MainNavigation = ({ setWalkInModal, setSavePage }) => {
    const { language, organization } = useSelector(state => state.auth);
    const { notification } = useSelector(state => state.layout);
    const { clearWorklistFilters, clearStudentsFilters, clearInboxFilters } = layoutActions;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [btnIsHighlighted, setBtnIsHighlighted] = useState(false);

    useQuery(GET_USER_BY_ID, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data.me.code === 450) {
                sessionStorage.clear()
                // window.location.href = "http://localhost:3002"
                window.location.href = "https://sso.ayamedica.com/"
            }
        }
    })

    useEffect(() => {
        setBtnIsHighlighted(true)
    }, [notification])


    useEffect(() => {
        if (pathname === '/') {
            navigate('/worklist')
        }

    }, [pathname, navigate])


    const option = pathname.split('/')[1];


    const panelLinks = [
        { id: "worklist", name: words.WORKLIST[language], icon: <AiOutlineUnorderedList /> },
        { id: "inbox", name: words.INBOX[language], icon: <BsBell /> },
        { id: "students", name: words.STUDENTS[language], icon: <FaUsers /> },
    ];

    const optionIndex = panelLinks.findIndex(obj => (obj.id === option));
    const btnClasses = `${classes.notificationCount} ${btnIsHighlighted ? classes.bump : ''}`;

    const resetInboxSection = (panLink) => {
        setSavePage(panLink)
        // debugger
        if (pathname.includes("walkin")) {
            setWalkInModal(true)
        } else {
            navigate(`/${panLink.id}`)
            dispatch(clearWorklistFilters())
            dispatch(clearStudentsFilters())
            dispatch(clearInboxFilters())
            // dispatch(removePageNumber())
            if (panLink.id === "inbox") {
                dispatch(layoutActions.saveInboxSection({ inboxSection: 0 }))
            }
        }
    }

    return (
        <aside style={language === 'ar' ? { right: 0, left: "auto" } : null}>
            <div className={classes.image} style={language === 'ar' ? { borderBottomLeftRadius: optionIndex !== -1 ? '45px' : '0' } : { borderBottomRightRadius: optionIndex !== -1 ? '45px' : '0' }}>
                <img src={logo} alt="Ayamedica Icon" />
                {
                    panelLinks.map((panLink, index) => {
                        if (option !== panLink.id && index < optionIndex) {
                            return (
                                <Link to={"#"} key={panLink.id} onClick={() => resetInboxSection(panLink)}>
                                    <div className={classes.notificationIcon}>
                                        <div style={{ display: "flex" }}>
                                            <span>{panLink.icon}</span>
                                            {panLink.id === "inbox" && notification > 0 &&
                                                <span style={{ color: "var(--primaryColor300)", backgroundColor: "var(--errorColor)" }} className={btnClasses}>
                                                    {notification}
                                                </span>
                                            }
                                            <span className={classes.linkName}>{panLink.name}</span>
                                        </div>

                                    </div>
                                </Link>
                            );
                        }
                        return true;
                    })
                }
            </div>

            <div className={classes.active} style={language === 'ar' ? { padding: "0 15px 0 0", width: "100%" } : { width: "100%" }}>
                {
                    panelLinks.map((panLink) => {
                        if (option === panLink.id) {
                            return (
                                <Link to={"#"} key={panLink.id} onClick={() => resetInboxSection(panLink)} style={language === 'ar' ? { padding: "15px 15px 15px 15px", width: "100%", borderTopRightRadius: "15px", borderBottomRightRadius: "15px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, } : { width: "100%" }}>
                                    <div className={classes.notificationIcon}>
                                        <div style={{ display: "flex", marginLeft: language === "ar" && "20px" }}>
                                            <span className={classes.icon}>{panLink.icon}</span>
                                            {panLink.id === "inbox" && notification > 0 &&
                                                <span style={{ color: "var(--primaryColor300)", backgroundColor: "var(--errorColor)" }} className={btnClasses}>
                                                    {notification}
                                                </span>
                                            }

                                            <span className={classes.linkName}>{panLink.name}</span>
                                        </div>

                                    </div>
                                </Link>
                            );
                        }
                        return true;
                    })
                }
            </div>

            <div className={classes.details} style={language === 'ar' ? { borderTopLeftRadius: optionIndex !== -1 ? '45px' : '0' } : { borderTopRightRadius: optionIndex !== -1 ? '45px' : '0' }}>
                <div className={classes.inactive}>
                    {
                        panelLinks.map((panLink, index) => {
                            if ((option !== panLink.id && index > optionIndex)) {
                                return (
                                    <Link to={"#"} key={panLink.id} onClick={() => resetInboxSection(panLink)}>
                                        <div className={classes.notificationIcon}>
                                            <div style={{ display: "flex" }}>
                                                <span>{panLink.icon}</span>
                                                {panLink.id === "inbox" && notification > 0 &&
                                                    <span style={{ color: "var(--primaryColor300)", backgroundColor: "var(--errorColor)" }} className={btnClasses}>
                                                        {notification}
                                                    </span>
                                                }
                                                <span className={classes.linkName}>{panLink.name}</span>
                                            </div>

                                        </div>
                                    </Link>
                                );
                            }
                            return true;
                        })
                    }
                </div>


                {
                    organization?.branchId &&
                    <div className={classes.orgData}>
                        <OrganizationIcon />
                        <p className='p-0 m-0 mb-1'>{organization?.name}</p>
                        <p>{organization?.branchName}</p>
                    </div>
                }

            </div>

        </aside >
    )
}

export default MainNavigation;