import { gql } from "@apollo/client";


export const CREATE_BRANCH = gql`
mutation CreateMyProviderSchoolBranch($input: CreateSchoolBranchInput!, $groups: [String], $creatorPosition: String!, $logo: Upload) {
  createMyProviderSchoolBranch(input: $input, groups: $groups, creatorPosition: $creatorPosition, logo: $logo) {
    _id
  }
}
`

export const UPDATE_BRANCH = gql`
mutation UpdateSchoolBranchInformation($branchId: ID!, $input: UpdateBranchInput, $groups: [String], $logo: Upload) {
  updateSchoolBranchInformation(branchId: $branchId, input: $input, groups: $groups, logo: $logo) {
    _id
  }
}
`

export const DELETE_BRANCH = gql`
mutation DeleteMyProviderBranch($branchId: ID!) {
  deleteMyProviderBranch(branchId: $branchId) {
    success
    message
  }
}
`

export const ADD_SUBGROUPS_TO_GROUPS = gql`
mutation CreateMultipleClasses($branchData: [createMultipleClasses]!, $branchId: ID!) {
  createMultipleClasses(branchData: $branchData, branchId: $branchId) {
    code
    success
    message
  }
}
`

export const ADD_SUBGROUPS_TO_GROUP = gql`
mutation AddSubGroupsToAGroup($branchId: ID!, $input: addSubGroupsInput!) {
  addSubGroupsToAGroup(branchId: $branchId, input: $input) {
    success
    message
  }
}
`

export const ADD_SUBGROUB_TO_GROUP = gql`
mutation AddASubGroupToAGroup($input: addASubGroupInput!, $branchId: ID!) {
  addASubGroupToAGroup(input: $input, branchId: $branchId) {
    success
    message
  }
}
`

export const EDIT_SUBGROUB_TO_GROUP = gql`
mutation EditSubGroupInGroup($input: EditSubGroupInGroupInput) {
  editSubGroupInGroup(input: $input) {
    success
    message
  }
}
`

export const DELETE_SUBGROUB_IN_GROUP = gql`
mutation RemoveSubGroupFromGroup($input: BranchGroupDataInput!) {
  removeSubGroupFromGroup(input: $input) {
    success
    message
  }
}
`

export const SEND_MEMBER_REQUEST = gql`
mutation CreateRequestToAddMemberToMyBranch($input: addMemberToBranchInput) {
  createRequestToAddMemberToMyBranch(input: $input) {
    success
    message
  }
}
`

export const CANCEL_REQUEST = gql`
mutation CancelRequestToBeAddedAsMember($branchId: ID!, $messageId: ID!) {
  cancelRequestToBeAddedAsMember(branchId: $branchId, messageId: $messageId) {
    code
    message
    success
  }
}
`

export const EDIT_MEMBER_INFO = gql`
mutation EditBranchUserData($input: EditBranchUserDataInput!) {
  editBranchUserData(input: $input) {
    success
    message
  }
}
`

export const DELETE_MEMBER = gql`
mutation RemoveMyBranchMember($memberId: ID!, $branchId: ID!) {
  removeMyBranchMember(memberId: $memberId, branchId: $branchId) {
    message
    success
  }
}
`

export const EDIT_MY_PROFILE = gql`
mutation UpdateMyInformation($input: UpdateMyInformationInput!, $profilePicture: Upload, $removeProfilePicture: Boolean) {
  updateMyInformation(input: $input, profilePicture: $profilePicture, removeProfilePicture: $removeProfilePicture) {
    isChanged
    message
    success
  }
}
`

export const CHANGE_PROFILE_PASSWORD = gql`
mutation ChangeMyPassword($currentPassword: String!, $newPassword: String!, $confirmPassword: String!) {
  changeMyPassword(currentPassword: $currentPassword, newPassword: $newPassword, confirmPassword: $confirmPassword) {
    message
    success
  }
}
`

export const CREATE_APPOINTMENT = gql`
mutation CreateSchoolAppointment($enableNotification: Boolean, $input: SchoolAppointmentInput) {
  createSchoolAppointment(enableNotification: $enableNotification, input: $input) {
    code
    message
    success
  }
}
`

export const EDIT_APPOINTMENT = gql`
mutation EditSchoolAppointment($appointmentId: ID!, $input: SchoolAppointmentInput) {
  editSchoolAppointment(appointmentId: $appointmentId, input: $input) {
    code
    success
    message
    data {
          _id
    }
  }
}
`

export const CANCEL_APPOINTMENT = gql`
mutation CancelSchoolAppointment($appointmentId: ID!, $cancelReason: String) {
  cancelSchoolAppointment(appointmentId: $appointmentId, cancelReason: $cancelReason) {
    code
    message
    success
  }
}
`

export const DELETE_APPOINTMENT = gql`
mutation DeleteSchoolAppointment($appointmentId: ID!) {
  deleteSchoolAppointment(appointmentId: $appointmentId) {
    success
    message
  }
}
`

export const CHECK_OUT_A_STUDENT = gql`
mutation CheckOutAStudent($appointmentId: ID!, $studentId: ID!, $input: Boolean!, $note: String, $undo: Boolean) {
  checkOutAStudent(appointmentId: $appointmentId, studentId: $studentId, input: $input, note: $note, undo: $undo) {
    success
    message
    code
  }
}
`

export const CHECK_OUT_APPOINTMENT = gql`
mutation CheckOutSchoolAppointment($appointmentId: ID!) {
  checkOutSchoolAppointment(appointmentId: $appointmentId) {
    success
    message
    code
  }
}
`

export const UPDATE_MEDICAL_RECORD = gql`
mutation UpdateSchoolMedicalRecord($branchId: ID!, $medicalRecordId: ID!, $notifyStatus: String, $medicalRecordInput: SchoolMedicalRecordInput) {
  updateSchoolMedicalRecord(branchId: $branchId, medicalRecordId: $medicalRecordId, notifyStatus: $notifyStatus, medicalRecordInput: $medicalRecordInput) {
    code
    success
    message
    medicalRecord {
      _id
      isFinished
    }
  }
}
`

export const CREATE_A_MEDICAL_RECORD = gql`
mutation CreateSchoolMedicalRecord($branchId: ID!, $patientId: ID!) {
  createSchoolMedicalRecord(branchId: $branchId, patientId: $patientId) {
    code
    success
    message
    appointment {
      _id
      appointmentType {
        medicalRecordId
      }
    }
  }
}
`

export const UPLOAD_EXCEL = gql`
mutation UploadExcel($path: String!, $branchId: ID!) {
  uploadExcel(path: $path, branchId: $branchId) {
    success
    message
  }
}
`

export const ADD_STUDENTS_FROM_EXCEL = gql`
mutation AddStudentsDataFromExcel($branchId: ID!, $confirmation: Boolean) {
  addStudentsDataFromExcel(branchId: $branchId, confirmation: $confirmation) {
    success
    message
  }
}
`

export const CREATE_STUDENT = gql`
mutation CreateAndAddStudentToSchool($input: StudentInput, $profilePicture: Upload) {
  createAndAddStudentToSchool(input: $input, profilePicture: $profilePicture) {
    code
    data {
      _id
    }
    message
    success
  }
}
`

export const EDIT_STUDENT = gql`
mutation UpdateStudentInformation($studentId: ID!, $input: UpdateStudentInput, $profilePicture: Upload) {
  updateStudentInformation(studentId: $studentId, input: $input, profilePicture: $profilePicture) {
    _id
  }
}
`

export const UPDATE_PHONE =  gql `
mutation UpdatePhoneNumber($countryCode: String!, $phoneNumber: String!) {
  updatePhoneNumber(countryCode: $countryCode, phoneNumber: $phoneNumber) {
    code
    message
    success
  }
}
`

export const TRANSFER_STUDENT = gql`
mutation TransferUserToAnotherSubGroup($input: BranchGroupDataInput!, $userId: ID!) {
  transferUserToAnotherSubGroup(input: $input, userId: $userId) {
    success
    message
  }
}
`

export const DELETE_STUDENT = gql`
mutation RemoveSubGoupUser($input: BranchGroupDataInput!, $userId: ID!) {
  removeSubGoupUser(input: $input, userId: $userId) {
    success
    message
  }
}
`

export const SEND_NEW_MESSAGE = gql`
mutation CreateMessageToStudent($input: MessageInput!) {
  createMessageToStudent(input: $input) {
    code
    message
    success
  }
}
`
export const OPEN_RECORD_REQUEST = gql`
mutation UpdateMyReceivedMessageAsRead($messageId: ID!) {
  updateMyReceivedMessageAsRead(messageId: $messageId) {
    code
    success
    message
    data {
      _id
    }
  }
}
`
export const DELETE_MESSAGE = gql`
mutation UpdateMyReceivedMessageAsDeleted($messageId: ID!) {
  updateMyReceivedMessageAsDeleted(messageId: $messageId) {
    _id
  }
}
`
export const UPDATE_SICKLEAVE_FOR_MOBILE_MEDICAL_RECORD = gql`
mutation UpdateSickLeaveForMobileMedicalRecord($branchId: ID!, $studentId: ID!, $medicalRecordId: ID!, $sickLeaveDays: Int!, $sickLeaveStartDate: Date!) {
  updateSickLeaveForMobileMedicalRecord(branchId: $branchId, studentId: $studentId, medicalRecordId: $medicalRecordId, sickLeaveDays: $sickLeaveDays, sickLeaveStartDate: $sickLeaveStartDate) {
    code
    success
    message
  }
}
`

export const IMPORT_PROGRESS = gql`
subscription ImportProgress {
  importProgress {
    completed
    total
  }
}
`

export const UPLOAD_PROGRESS = gql`
subscription ProgrssUpload {
  progrssUpload {
    completed
    total
  }
}
`

export const UPDATE_BRANCH_WHITELIST = gql `
mutation UpdateBranchWhiteList($drugsIds: [ID]!, $branchId: ID!) {
  updateBranchWhiteList(drugsIds: $drugsIds, branchId: $branchId) {
    code
    success
    message
  }
}
`

export const CHECKOUT_HYGIENE_STUDENT = gql `
mutation CheckoutHygieneStudent($appointmentId: ID!, $studentId: ID!, $reason: String, $hygieneName: String, $isChecked: Boolean, $isAbscent: Boolean, $allIsOk: Boolean, $undo: Boolean) {
  checkoutHygieneStudent(appointmentId: $appointmentId, studentId: $studentId, reason: $reason, hygieneName: $hygieneName, isChecked: $isChecked, isAbscent: $isAbscent, allIsOk: $allIsOk, undo: $undo) {
    code
    message
    success
  }
}
`

export const CREATE_TICKET = gql`
mutation CreateUserTicket($country: Country, $source: Source, $platform: Platform, $comment: String, $ticketReason: ticketReason) {
  createUserTicket(country: $country, source: $source, platform: $platform, comment: $comment, ticketReason: $ticketReason) {
    code
    success
    message
  }
}
`