import { gql } from "@apollo/client";

export const GET_USER_BY_TOKEN = gql`
query ReadUserDataByToken($token: String!, $branchId: ID) {
  readUserDataByToken(token: $token, branchId: $branchId) {
    code
    success
    message
    role
    position
    data {
      firstName
      lastName
      _id
      aId
      profilePictureUrl
      countryCode
      phoneNumber
      isVerified
    }
    branch {
      _id
      logoUrl
      name
      branchType {
        value
      }
      address {
        country {
          key
          value
        }
      }
      providerId {
        _id
        logoUrl
        name
      }
      subscriptionDetails {
        _id
        isFreeTrial
        packageKey
      }
    }
  }
}
`

export const GET_USER_BY_NUMBER = gql`
query ReadUserDetailsByChosenIdLimited($chosenId: String!) {
  readUserDetailsByChosenIdLimited(chosenId: $chosenId) {
    _id
    aId
    firstName
    lastName
    profilePictureUrl
  }
}
`;

export const CHECK_IF_USER_EXISTS = gql`
query CheckIfUserExistsByPhoneNumberForWeb($countryCode: String!, $phoneNumber: String!) {
  checkIfUserExistsByPhoneNumberForWeb(countryCode: $countryCode, phoneNumber: $phoneNumber) {
    message
    success
  }
}
`

export const GET_ALL_ORGANIZATIONS = gql`
query ReadMyProviders {
  readMyProviders {
    _id
    subscriptionDetails {
      _id
      isFreeTrial
      packageKey
      dueDate
      startDate
    }
    name
    logoUrl
    branches {
     _id
    name
    logoUrl
    branchType {
      key
    }
    address {
      country {
        key
      }
    }
      role
      position
    }
    role
  }
}
`;

export const GET_BRANCHE_TYPES = gql`
query ReadBranchTypeLookUps {
  readBranchTypeLookUps {
    key
    value
  }
}
`;

export const READ_COUNTRIES = gql`
query ReadCountries {
  readCountries {
    data {
      key
    value
    }
    code
    message
    success
  }
}
`;

export const READ_CITIES_BY_GOVERNORATE = gql`
query ReadCitiesByGovernorate($governorate: String!) {
  readCitiesByGovernorate(governorate: $governorate) {
    code
    success
    message
    data {
       key
    value
    }
  }
}
`;

export const GET_USER_BY_ID = gql`
query Me {
  me {
    code
    success
    message
    user {
        _id
    aId
    firstName
    lastName
    email
    countryCode
    phoneNumber
    profilePictureUrl
    isVerified
    gender {
      key
      value
    }
    bloodType {
      id
      key
      value
    }
    dateOfBirth
    height
    weight
    insuranceCompany
    membershipNumber
    insuranceId
    policyNumber
    nationalId
    passportId
    countryOfCitizenship {
      key
      value
    }
    role
    address {
      country {
        key
        value
      }
      governorate {
        key
        value
      }
      city {
        key
        value
      }
      area
      street
      postalCode
    }
    }
  }
}
`;

export const READ_AYAMEDICA_INFO_KEYS = gql`
query ReadAyamedicaInformationKeys {
  readAyamedicaInformationKeys {
    code
    message
    success
    data {
    aboutUs
    emailAddress
    phoneNumber
    privacyPolicy
    profilePictureBaseURL
    termsAndConditions
    medicalRecordPictureBaseURL
    providerPictureBaseURL
    boardScreenBaseURL
    usersURL
    monolithURL
    ayamedicaSchoolURL
    ayamedicaDashboardURL
    SSOURL 
    }
  }
}
`;

export const READ_AYAMEDICA_INFO = gql`
query ReadAyamedicaInformation($input: String) {
  readAyamedicaInformation(input: $input) {
    id
    key
    value
  }
}
`;