import { useSelector } from "react-redux";
import questionMark from "../../assets/images/confirm.png";
import words from "../../assets/words";
import classes from "./ConfirmationMessage.module.scss";
import OutlinedButton from "./OutlinedButton";
import Button from "./Button";

const ConfirmationMessage = ({ message, confirm, onHide, content, confirmLoading,cancelLoading, cancel, yesTitle, noTitle }) => {
  const language = useSelector((state) => state.auth.language);

  return (
    <div className={classes.container}>
      {content ? content : <img src={questionMark} alt="Confirm" />}

      <p>{message}</p>

      <div className={classes.actionBtns}>
       
        <div>
          <Button onClick={confirm} loading={confirmLoading}>
            {yesTitle || words.YES[language]}
          </Button>
        </div>
        <div>
          <OutlinedButton onClick={cancel ? cancel : onHide} loading={cancelLoading}>
            {noTitle ||  words.NO[language]}
          </OutlinedButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationMessage;
