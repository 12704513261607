import { createSlice } from "@reduxjs/toolkit";
import { formatDateToAPI } from "../../util/formatWords";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    trialToast: true,
    // pageNumber: JSON.parse(sessionStorage.getItem("pageNumber")) || 1,
    notification: 0,
    vaccineType: "",
    worklistFilters: JSON.parse(sessionStorage.getItem("worklistFilters")) || {
      branchId: null,
      startDate: formatDateToAPI(
        `${new Date().getDate()}/${
          new Date().getMonth() + 1
        }/${new Date().getFullYear()}`
      ),
      endDate: "",
      appointmentType: "",
      vaccineType: [],
      groupKeys: [],
      subGroups: [],
      diseaseNames: [],
      diseasesTypes: [],
      studentIds: [],
    },
    worklistStatus: JSON.parse(sessionStorage.getItem("worklistStatus")) || "CHECK_IN",
    inboxSection: JSON.parse(sessionStorage.getItem("inboxSection")) || 0,
    inboxPageNumber: JSON.parse(sessionStorage.getItem("inboxPageNumber")) || 1,
    studentsFilters: JSON.parse(sessionStorage.getItem("studentsFilters")) || {
      diseaseNames: [],
      diseasesTypes: [],
      gradeKeys: [],
      subGroupsIds: [],
      minMedicalRecord: null,
      maxMedicalRecord: null
    },
    inboxFilters: JSON.parse(sessionStorage.getItem("inboxFilters")) || {
      startDate: "",
      endDate: "",
      groupKeys: [],
      subGroups: [],
      studentIds: [],
      vaccineType: [],
      doctor: [],
      speciality: [],
      medicalRecords: null,
    },
  },
  reducers: {
    // savePageNumer: (state, action) => {
    //   state.pageNumber = action.payload;
    //   sessionStorage.setItem("pageNumber", action.payload);
    // },
    // removePageNumber: (state) => {
    //   state.pageNumber = 1;
    //   sessionStorage.removeItem("pageNumber");
    // },
    saveNotificationNumber: (state, action) => {
      state.notification = action.payload.notificationNumber;
    },
    saveWorklistFilters: (state, action) => {
      state.worklistFilters = action.payload.worklistFilters;
      const worklistFiltersJson = JSON.stringify(state.worklistFilters);
      sessionStorage.setItem("worklistFilters", worklistFiltersJson);
    },
    clearWorklistFilters: (state) => {
      state.worklistFilters = {
        ...state.worklistFilters,
        branchId: null,
        // startDate: formatDateToAPI(
        //   `${new Date().getDate()}/${
        //     new Date().getMonth() + 1
        //   }/${new Date().getFullYear()}`
        // ),
        // endDate: "",
        appointmentType: [],
        groupKeys: [],
        subGroups: [],
        diseaseNames: [],
        diseasesTypes: [],
        studentIds: [],
      };
      sessionStorage.removeItem("worklistFilters");
    },
    saveWorklistStatus: (state, action) => {
      state.worklistStatus = action.payload.worklistStatus;
      const worklistStatusJson = JSON.stringify(state.worklistStatus);
      sessionStorage.setItem("worklistStatus", worklistStatusJson);
    },
    saveVaccineType: (state, action) => {
      state.vaccineType = action.payload.vaccineType;
    },
    saveInboxSection: (state, action) => {
      state.inboxSection = action.payload.inboxSection;
      state.inboxPageNumber = action.payload.inboxPageNumber;
      sessionStorage.setItem("inboxSection", state.inboxSection);
      sessionStorage.setItem("inboxPageNumber", state.inboxPageNumber)
    },
    saveInboxFilter: (state, action) => {
      state.inboxFilters = action.payload;
      sessionStorage.setItem("inboxFilters", JSON.stringify(action.payload))
    },
    clearInboxFilters: (state) => {
      state.inboxFilters = {
        startDate: "",
        endDate: "",
        groupKeys: [],
        subGroups: [],
        studentIds: [],
        vaccineType: [],
        doctor: [],
        speciality: [],
        medicalRecords: null,
      }
      sessionStorage.removeItem("inboxFilters")
    },
    saveStudentsFilter: (state, action) => {
      state.studentsFilters = action.payload;
      sessionStorage.setItem("studentsFilters", JSON.stringify(action.payload))
    },
    clearStudentsFilters: (state) => {
      state.studentsFilters = {
        diseaseNames: [],
        diseasesTypes: [],
        gradeKeys: [],
        subGroupsIds: [],
        minMedicalRecord: null,
        maxMedicalRecord: null
      }
      sessionStorage.removeItem("studentsFilters")
    },
    closeTrialToast: (state, action) => {
      state.trialToast = action.payload;
    }
  },
});

// export const { savePageNumer, removePageNumber } = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
