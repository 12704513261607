import { createPortal } from "react-dom";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import classes from "./withModal.module.scss";

const withModal = (Component) => (props) => {
  const language = useSelector((state) => state.auth.language);

  let modalClasses = [classes.modal];

  if (props.dialogClassName) {
    modalClasses.push(props.dialogClassName);
  }

  if (language === "ar") {
    modalClasses = modalClasses.filter((c) => c !== c.modal);
    modalClasses.push(classes.rtlModal);
  }

  return createPortal(
    <Modal
      centered
      animation={true}
      scrollable={false}
      show={props.show}
      onHide={props.onHide}
      dialogClassName={modalClasses}
      backdrop={props.title ? "static" : true}
    >
      {props.title && (
        <Modal.Header closeButton={props.showCloseBtn === false ? false : true} className={classes.modalHeader}>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <Component {...props} />
      </Modal.Body>
    </Modal>,
    document.getElementById("modal-root")
  );
};

export default withModal;
