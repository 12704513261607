import firebase from 'firebase/compat/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// var firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

var firebaseConfig = {
    apiKey: "AIzaSyAEyIoWex4p2FqB6Kh-5c82620gLaFxGgE",
    authDomain: "ayamedica-b615c.firebaseapp.com",
    projectId: "ayamedica-b615c",
    storageBucket: "ayamedica-b615c.appspot.com",
    messagingSenderId: "325673666873",
    appId: "1:325673666873:web:fdc6df246136e03fcbc0e8",
    measurementId: "G-P9ZCJNV2Q0"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = (setFirebaseToken) => {
    // return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
    return getToken(messaging, { vapidKey: 'BBbSsRbgUCTROf7wq_wp--Y2GzF4w3MFozH01JrC-bRVofLtXQFfg3VUDp4Ic8AbPmtfUd50fj4LkzlaBHTXBjg' }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            setFirebaseToken(currentToken);
            // return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            // debugger
            // console.log('No registration token available. Request permission to generate one.');
            setFirebaseToken(null);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });