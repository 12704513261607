export const firstLoader = () => {
  const token = sessionStorage.getItem("token");
  if(!token) {
    window.location.href = 'https://sso.ayamedica.com/';
  }
}

export function orgLoader() {
  // debugger
  const token = sessionStorage.getItem("token");
  const language = sessionStorage.getItem("language");
  const generalInfo = sessionStorage.getItem("generalInfo");
  const incomingToken = document.location.search.split('?')[1]?.split('token=')[1];


  if (!language) {
    sessionStorage.setItem("language", "en");
  }

  // debugger
  if (!token && !incomingToken) {
    // const redirectURL = generalInfo?.ayamedicaSSOURL ? generalInfo?.ayamedicaSSOURL : "http://localhost:3002/";
    const redirectURL = generalInfo?.ayamedicaSSOURL ? generalInfo?.ayamedicaSSOURL : 'https://sso.ayamedica.com/';
    window.location.href = redirectURL;
    return null;
  }
  else {
    return null; 
  }
}