import { useSelector } from "react-redux";
import classes from './OrganizationIcon.module.scss'

const OrganizationIcon = () => {
    const { generalInfo, organization } = useSelector(state => state.auth);

    return (
        <div className={classes.outerDiv}>
            <div className={classes.innerDiv}>
                <img src={generalInfo?.providerPictureBaseURL + organization?.branchLogoUrl} alt="School" />
            </div>
        </div>
    )
}

export default OrganizationIcon