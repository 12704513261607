import { createSlice } from "@reduxjs/toolkit";

export const walkinSlice = createSlice({
  name: "walkin",
  initialState: {
    currentMedicalRecord: {},
    medicalRecord: {
      category: "PEDIATRICS",
      input: {
        pediatrics: {
          complaints: [],
          physicalExamFinding: [],
          assessment: {
            assessmentNotes: [],
            conclusion: [],
          },
          plan: [],
        },
      },
    },
    examinationData: [],
    medicalRecordId: sessionStorage.getItem("medicalRecordId") || "",
    appointmentId: sessionStorage.getItem("appointmentId"),
    recognitionList: [],
  },
  reducers: {
    saveRecognitionList: (state, action) => {
      state.recognitionList = [...state.recognitionList, action.payload]
    },
    saveCurrentMedicalRecord: (state, action) => {
      state.currentMedicalRecord = action.payload;
      state.recognitionList = action.payload.assessment?.recognition || [];
    },
    saveExaminationData: (state, action) => {
      state.examinationData = action.payload;
    },
    saveMedicalRecordId: (state, action) => {
      state.medicalRecordId = action.payload;
      sessionStorage.setItem("medicalRecordId", JSON.stringify(action.payload));
    },
    saveAppoinmentId: (state, action) => {
      state.appointmentId = action.payload;
      sessionStorage.setItem("appoinmentId", JSON.stringify(action.payload));
    },
    resetData: (state) => {
      state.medicalRecord = {
        category: "PEDIATRICS",
        input: {
          pediatrics: {
            complaints: [],
            physicalExamFinding: [],
            assessment: {
              assessmentNotes: [],
              conclusion: [],
            },
            plan: [],
          },
        },
      };
      state.medicalRecordId = "";
      state.appointmentId = "";
      state.currentMedicalRecord = {
        complaints: [],
        assessment: [],
        assessmentNotes: [],
        physicalExamination: [],
        plan: [],
        sickLeave: null,
        sickLeaveDays: null,
      };
      state.recognitionList = [];
      sessionStorage.removeItem("medicalRecordId");
      sessionStorage.removeItem("appoinmentId");
    },
    saveNewComplaint: (state, action) => {
      state.currentMedicalRecord.complaints = [
        ...state.currentMedicalRecord.complaints,
        action.payload.complaint,
      ];
    },
    removeComplaint: (state, action) => {
      state.currentMedicalRecord.complaints =
        state.currentMedicalRecord.complaints.filter(
          (complanit) => complanit !== action.payload
        );
    },
    saveNewNote: (state, action) => {
      state.currentMedicalRecord.assessmentNotes = [
        ...state.currentMedicalRecord.assessmentNotes,
        action.payload.note,
      ];
    },
    removeNote: (state, action) => {
      state.currentMedicalRecord.assessmentNotes =
        state.currentMedicalRecord.assessmentNotes.filter(
          (note) => note !== action.payload
        );
    },
    saveMedicalRecordData: (state, action) => {
      if (action.payload.title === "physicalExamination") {
        const index = state.currentMedicalRecord[
          "physicalExamination"
        ].findIndex((item) => item.key === action.payload.data.type);
        index === -1 //if index is -1, it means that the item is not in the array
          ? state.currentMedicalRecord[`physicalExamination`]?.push(
            action.payload.data
          )
          : (state.currentMedicalRecord[`physicalExamination`][index] = //if index is not -1, it means that the item is in the array
            action.payload.data)

        state.currentMedicalRecord["physicalExamination"] = state.currentMedicalRecord["physicalExamination"]?.sort((a, b) => a?.sort - b?.sort)

      } else if (action.payload.title === "assessment") {

        state.currentMedicalRecord.assessment = {
          suspectedDisease: action.payload.data?.suspectedDisease,
          recommendation: action.payload.data?.recommendation
        }

        // if(action.payload.data.type === "suspectedDisease") {
        //   state.currentMedicalRecord.assessment.suspectedDisease = [...state.currentMedicalRecord.assessment.suspectedDisease, action.payload.data.value]
        // }else{
        //   state.currentMedicalRecord.assessment.recommendation = [...state.currentMedicalRecord.assessment.recommendation, action.payload.data.value]
        // }

      } else {
        //if the title is not physicalExamFinding, we can just push the data to the array
        state.currentMedicalRecord[`${action.payload.title}`] =
          action.payload.data;
      }
    },
    removeConclusion: (state, action) => {
      if (action.payload.type === "general") {
        state.currentMedicalRecord.assessment.general = state.currentMedicalRecord.assessment.general.filter((field) => field !== action.payload.value)
      } else {
        state.currentMedicalRecord.assessment.recognition = state.currentMedicalRecord.assessment.recognition.filter((field) => field?.value !== action.payload.data?.value)
      }
    },
    removeDrug: (state, action) => {
      state.currentMedicalRecord.plan = state.currentMedicalRecord.plan.filter(
        (field) => field.drugName !== action.payload.drugName
      );
    },
    saveSickLeave: (state, action) => {
      state.currentMedicalRecord.sickLeave = action.payload.sickLeave;
      state.currentMedicalRecord.sickLeaveDays = action.payload.sickLeaveDays;
    },
  },
});

export const walkinReducer = walkinSlice.reducer;
