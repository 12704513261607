import { useDispatch, useSelector } from "react-redux";
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { Outlet } from "react-router-dom";
import classes from "./HomePage.module.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_USER_BY_TOKEN, READ_AYAMEDICA_INFO_KEYS } from "../../graphql/auth/Queries";
import { authActions, layoutActions } from "../../store";
import { READ_NOTIFICATION_NUMBERS } from "../../graphql/main/Queries";
import { useEffect, useState } from "react";
import CryptoJS from 'crypto-js';

const HomePage = () => {
    const seckret_key = process.env.REACT_APP_SECRET_KEY;

    const { organization, language, token: savedToken } = useSelector((state) => state.auth);
    const user = sessionStorage.getItem("user");
    const token = document.location.search.split('?')[1]?.split('token=')[1];
    // const token = CryptoJS.AES.decrypt(document.location.search.split('?')[1]?.split('token=')[1], 'token-123');
    const [branchId, setBranchId] = useState(document.location.search.split('branchId=')[1]);
    const dispatch = useDispatch();


    const [decreptedToken, setDecreptedToken] = useState("")

    const [gettingUserData] = useLazyQuery(GET_USER_BY_TOKEN, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            // debugger
            if (data.readUserDataByToken.success) {
                dispatch(authActions.login({
                    token: decreptedToken || savedToken,
                    user: data?.readUserDataByToken?.data,
                }));
                dispatch(authActions.saveBranchData({
                    providerId: data?.readUserDataByToken?.branch.providerId._id,
                    organizationLogoUrl: data?.readUserDataByToken?.branch.providerId.logoUrl,
                    subscriptionId: data?.readUserDataByToken?.branch.subscriptionDetails._id,
                    branchId: data?.readUserDataByToken?.branch._id,
                    branchType: data?.readUserDataByToken?.branch.branchType.value,
                    branchName: data?.readUserDataByToken?.branch.name,
                    name: data?.readUserDataByToken?.branch.providerId.name,
                    branchLogoUrl: data?.readUserDataByToken?.branch.logoUrl,
                    country: data?.readUserDataByToken?.branch.address.country.key,
                    isFreeTrial: data?.readUserDataByToken?.branch.subscriptionDetails.isFreeTrial,
                    packageKey: data?.readUserDataByToken?.branch.subscriptionDetails.packageKey,
                    role: data?.readUserDataByToken?.role,
                    position: data?.readUserDataByToken?.position,
                }))
                // window.location.href = "/"

                var url = document.location.href;
                window.history.pushState({}, "", url.split("?")[0]);
            }
        }
    });


    useQuery(READ_AYAMEDICA_INFO_KEYS, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            dispatch(
                authActions.saveAyamedicaInfo({
                    data: data.readAyamedicaInformationKeys?.data,
                })
            );
        },
    });

    const [getNotificationNumber] = useLazyQuery(READ_NOTIFICATION_NUMBERS, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            dispatch(
                layoutActions.saveNotificationNumber({
                    notificationNumber:
                        data.notifactionBadgeNumber.data.unReadMedicalRecordLength +
                        data.notifactionBadgeNumber.data.unReadOtherMessagesLength,
                })
            );
        },
    });

    useEffect(() => {
        if (token) {
            const decryptedBytes = CryptoJS.AES.decrypt(token, seckret_key);
            const tokenBites = decryptedBytes.toString(CryptoJS.enc.Utf8)
            setDecreptedToken(tokenBites)
            dispatch(authActions.saveToken(tokenBites))
        }
    }, [token])

    useEffect(() => {
        if (organization?.branchId) {
            getNotificationNumber({
                variables: {
                    branchId: organization?.branchId,
                }
            });
        }
        // readUser()
    }, [organization?.branchId]);

    useEffect(() => {
        if (decreptedToken && branchId && !user) {
            sessionStorage.setItem('token', decreptedToken);
            gettingUserData({
                variables: {
                    token: decreptedToken,
                    branchId: branchId
                },
            });
        } else if (decreptedToken && branchId && user) {
            sessionStorage.setItem('token', decreptedToken);
            gettingUserData({
                variables: {
                    token: decreptedToken,
                    branchId: branchId
                },
            });
        }
    }, [decreptedToken]);


    return (
        <div className={language === 'ar' ? classes.arStyles : classes.enStyles} lang={language === 'ar' ? 'ar' : undefined}>
            <ThemeProvider dir={language === 'ar' ? "rtl" : "ltr"}>
                <Outlet />
            </ThemeProvider>
        </div>
    );
};

export default HomePage;